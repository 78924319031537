import {Injectable, NgZone} from '@angular/core';
import {FirebaseAuthentication, GetIdTokenOptions, ProviderId, SignInCustomParameter, SignInResult, User,} from '@capacitor-firebase/authentication';
import {environment} from '@env/environment';
import {isPlatform} from '@ionic/angular';
import {getApp, initializeApp} from 'firebase/app';
import {
  Auth,
  getAuth,
  initializeAuth,
  RecaptchaVerifier,
} from 'firebase/auth';
import {ReplaySubject} from 'rxjs';
import {FirebaseAnalytics} from "@capacitor-firebase/analytics";
import {DialogService} from "@app/services";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject = new ReplaySubject<User>(1);
  public currentUser$ = this.currentUserSubject.asObservable();
  public token?: string;

  private recaptchaVerifier?: RecaptchaVerifier;
  private auth?: Auth;

  constructor(private readonly ngZone: NgZone,
              private readonly dialogs: DialogService) {
    const attachListeners = () => {
      FirebaseAuthentication.removeAllListeners().then(() => {
        FirebaseAuthentication.addListener('authStateChange', (change) => {
          this.ngZone.run(() => {
            this.handle(change.user)
          });
        });
      });
    };

    if (isPlatform('capacitor')) {
      attachListeners();
    } else {
      FirebaseAuthentication.getRedirectResult()
        .then(result => {
          if (result.user) {
            this.handle(result.user);
          }
        })
        .then(() => attachListeners());
    }
  }

  private handle(user: User | null | undefined) {
    if (!user) {
      this.signInAnonymously();
      return;
    }

    FirebaseAnalytics.setUserId({
      userId: user.uid,
    });
    this.getIdToken()
      .then(idToken => this.token = idToken)
      .then(() => this.currentUserSubject.next(user));
    return user;
  }

  public async initialize(): Promise<void> {
    if (isPlatform('capacitor')) {
      return;
    }
    /**
     * Only needed if the Firebase JavaScript SDK is used.
     *
     * Read more: https://github.com/robingenz/capacitor-firebase/blob/main/packages/authentication/docs/firebase-js-sdk.md
     */
    initializeApp(environment.firebase);

    this.auth = await getAuth();
    this.recaptchaVerifier = new RecaptchaVerifier(this.auth, 'recaptcha-container', {"size": "invisible"});
  }

  private async getIdToken(options?: GetIdTokenOptions): Promise<string> {
    const result = await FirebaseAuthentication.getIdToken(options);
    return result.token;
  }

  public async signInWithApple(customParameters: SignInCustomParameter[] = []) {
    return await FirebaseAuthentication.signInWithApple({customParameters});
  }

  public async signInWithFacebook(customParameters: SignInCustomParameter[] = []) {
    return await FirebaseAuthentication.signInWithFacebook({customParameters, mode: isPlatform('capacitor') ? 'popup' : 'redirect'});
  }

  public async signInWithGoogle(customParameters: SignInCustomParameter[] = []) {
    return await FirebaseAuthentication.signInWithGoogle({customParameters, mode: isPlatform('capacitor') ? 'popup' : 'redirect'});
  }

  public async signInAnonymously() {
    return await FirebaseAuthentication.signInAnonymously();
  }

  public async signInWithPhone(phoneNumber?: string): Promise<SignInResult> {
    return this.handlePhoneVerification('signInWithPhoneNumber', phoneNumber);
  }

  public async signOut() {
    await FirebaseAuthentication.signOut();
  }

  public async unlink(providerId: SupportedProviderId) {
    await FirebaseAuthentication.unlink({
      providerId
    })
  }

  public async link(provider: SupportedProviderId, fallbackToSignIn: boolean) {
    console.log(`linking with ${provider} fallback ${fallbackToSignIn}`);
    const promise = (() => {
      switch (provider) {
        case ProviderId.PHONE:
          return this.linkWithPhone();
        case ProviderId.APPLE:
          return this.linkWithApple();
        case ProviderId.FACEBOOK:
          return this.linkWithFacebook();
        case ProviderId.GOOGLE:
          return this.linkWithGoogle();
        default:
          throw 'linking not supported with ' + provider;
      }
    });

    const result = await promise();
    await FirebaseAuthentication.reload();
    return result;
  }

  public async linkWithApple() {
    return await FirebaseAuthentication.linkWithApple({mode: isPlatform('capacitor') ? 'popup' : 'redirect'});
  }

  public async linkWithFacebook() {
    return await FirebaseAuthentication.linkWithFacebook({mode: isPlatform('capacitor') ? 'popup' : 'redirect'});
  }

  public async linkWithGoogle() {
    const result = await FirebaseAuthentication.linkWithGoogle({mode: isPlatform('capacitor') ? 'popup' : 'redirect'})
  }

  public async linkWithPhone(): Promise<SignInResult> {
    return this.handlePhoneVerification('linkWithPhoneNumber');
  }

  async signIn(provider: SupportedProviderId): Promise<SignInResult> {
    switch (provider) {
      case ProviderId.PHONE:
        return this.signInWithPhone();
      case ProviderId.APPLE:
        return this.signInWithApple();
      case ProviderId.FACEBOOK:
        return this.signInWithFacebook();
      case ProviderId.GOOGLE:
        return this.signInWithGoogle();
      default:
        throw 'signin not supported with ' + provider;
    }
  }

  private async handlePhoneVerification(
    action: 'signInWithPhoneNumber' | 'linkWithPhoneNumber', phoneNumber?: string
  ): Promise<SignInResult> {
    return new Promise(async (resolve, reject) => {
      // Listener cleanup utility
      const cleanupListeners = (listeners: any[]) => listeners.forEach((listener) => listener.remove());

      try {
        // Attach listeners
        const listeners = [
          await FirebaseAuthentication.addListener('phoneCodeSent', async (event) => {
            cleanupListeners(listeners);
            try {
              // Ask for verification code
              const verificationCode = await this.dialogs
                .showInputAlert({
                  inputs: [
                    {
                      name: 'verificationCode',
                      type: 'number',
                      placeholder: 'Code',
                    },
                  ],
                })
                .then((data) => data.verificationCode || '');

              // Confirm the verification code
              const result = await FirebaseAuthentication.confirmVerificationCode({
                verificationId: event.verificationId,
                verificationCode,
              });

              resolve(result); // Resolve with result
            } catch (error) {
              reject(error); // Reject if confirmation fails
            }
          }),

          await FirebaseAuthentication.addListener('phoneVerificationCompleted', async (event) => {
            cleanupListeners(listeners);
            resolve(event); // Resolve directly
          }),
        ];

        // Ask for phone number
        const phone = phoneNumber || await this.dialogs
          .showInputAlert({
            inputs: [
              {
                name: 'phone',
                type: 'tel',
                placeholder: 'Phone (+48...)',
              },
            ],
          })
          .then((data) => data.phone || '');

        // Perform the requested action (signIn or link)
        await FirebaseAuthentication[action]({
          phoneNumber: `+48${phone}`,
          recaptchaVerifier: this.recaptchaVerifier,
        });
      } catch (error) {
        reject(error); // Reject the promise if any error occurs
      }
    });
  }
}

export type SupportedProviderId = ProviderId.APPLE | ProviderId.FACEBOOK | ProviderId.GOOGLE | ProviderId.PHONE;
