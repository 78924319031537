import {Injectable, NgZone} from '@angular/core';
import {
  ConfirmVerificationCodeOptions,
  FirebaseAuthentication,
  GetIdTokenOptions,
  PhoneVerificationCompletedEvent,
  SignInResult,
  SignInWithPhoneNumberOptions,
  User,
} from '@capacitor-firebase/authentication';
import {Capacitor} from '@capacitor/core';
import {environment} from '@env/environment';
import {isPlatform, Platform} from '@ionic/angular';
import {initializeApp} from 'firebase/app';
import {getAuth, RecaptchaVerifier} from 'firebase/auth';
import {lastValueFrom, Observable, ReplaySubject, Subject, take} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthenticationService {
  private currentUserSubject = new ReplaySubject<User | null>(1);
  private phoneVerificationCompletedSubject =
    new Subject<PhoneVerificationCompletedEvent>();
  private phoneCodeSentSubject = new Subject<{
    verificationId: string;
  }>();
  private recaptchaVerifier?: RecaptchaVerifier;

  constructor(
    private readonly ngZone: NgZone,
  ) {
    FirebaseAuthentication.removeAllListeners().then(() => {
      FirebaseAuthentication.addListener('authStateChange', (change) => {
        this.ngZone.run(() => {
          this.currentUserSubject.next(change.user);
        });
      });
      FirebaseAuthentication.addListener(
        'phoneVerificationCompleted',
        async (event) => {
          this.ngZone.run(() => {
            this.phoneVerificationCompletedSubject.next(event);
          });
        },
      );
      FirebaseAuthentication.addListener('phoneCodeSent', async (event) => {
        this.ngZone.run(() => {
          this.phoneCodeSentSubject.next(event);
        });
      });
    });
  }

  public get currentUser$(): Observable<User | null> {
    return this.currentUserSubject.asObservable();
  }

  public get phoneVerificationCompleted$(): Observable<PhoneVerificationCompletedEvent> {
    return this.phoneVerificationCompletedSubject.asObservable();
  }

  public get phoneCodeSent$(): Observable<{
    verificationId: string;
  }> {
    return this.phoneCodeSentSubject.asObservable();
  }

  public async initialize(): Promise<void> {
    if (isPlatform('capacitor')) {
      return;
    }
    /**
     * Only needed if the Firebase JavaScript SDK is used.
     *
     * Read more: https://github.com/robingenz/capacitor-firebase/blob/main/packages/authentication/docs/firebase-js-sdk.md
     */
    initializeApp(environment.firebase);

    const auth = getAuth();
    this.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {"size": "invisible"});
  }

  public async getRedirectResult(): Promise<SignInResult | undefined> {
    if (Capacitor.isNativePlatform()) {
      return;
    }
    return FirebaseAuthentication.getRedirectResult();
  }

  public confirmVerificationCode(
    options: ConfirmVerificationCodeOptions,
  ): Promise<SignInResult> {
    return FirebaseAuthentication.confirmVerificationCode(options);
  }

  public getCurrentUser(): Promise<User | null> {
    return lastValueFrom(this.currentUser$.pipe(take(1)));
  }

  public async getIdToken(options?: GetIdTokenOptions): Promise<string> {
    const result = await FirebaseAuthentication.getIdToken(options);
    return result.token;
  }

  public async setLanguageCode(languageCode: string): Promise<void> {
    await FirebaseAuthentication.setLanguageCode({ languageCode });
  }

  public async signInWithApple(): Promise<void> {
    await FirebaseAuthentication.signInWithApple();
  }

  public async signInWithFacebook(): Promise<void> {
    await FirebaseAuthentication.signInWithFacebook();
  }

  public async signInWithGoogle(): Promise<void> {
    await FirebaseAuthentication.signInWithGoogle();
  }

  public signInWithPhoneNumber(
    options: SignInWithPhoneNumberOptions,
  ): Promise<void> {
    return FirebaseAuthentication.signInWithPhoneNumber({...options, recaptchaVerifier: this.recaptchaVerifier});
  }

  public async signOut(): Promise<void> {
    await FirebaseAuthentication.signOut();
  }

  public async useAppLanguage(): Promise<void> {
    await FirebaseAuthentication.useAppLanguage();
  }

  public async linkWithApple() {
    await FirebaseAuthentication.linkWithApple();
  }

  public async linkWithFacebook() {
    await FirebaseAuthentication.linkWithFacebook();
  }

  public async linkWithGoogle() {
    await FirebaseAuthentication.linkWithGoogle();
  }

  public async linkWithPhoneNumber(
      options: SignInWithPhoneNumberOptions,
  ) {
    return FirebaseAuthentication.linkWithPhoneNumber({...options, recaptchaVerifier: this.recaptchaVerifier});
  }
}
