import {APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom} from '@angular/core';

import {HttpLoaderFactory, initializeAppFactory} from './app/app.component';
import {environment} from './environments/environment';
import {Preferences} from "@capacitor/preferences";
import {PreloadAllModules, provideRouter, RouteReuseStrategy, TitleStrategy, withPreloading} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AuthService, CustomTitleStrategy, GlobalErrorHandlerService} from './app/services';
import {bootstrapApplication, BrowserModule} from '@angular/platform-browser';
import {HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {GraphQLModule} from '@app/gql/graphql.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AppComponent} from './app/app.component';
import {BASE_URL, CONFIGURED_CUSTOM_URL, CONFIGURED_ENVIRONMENT} from "@app/injectors";

import { routes } from './app/app.routes';

if (environment.production) {
  enableProdMode();
}

async function main() {
  const configuredEnvironment = await Preferences.get({key: 'environment'}).then(p => p.value);
  const configuredCustomUrl = await Preferences.get({key: 'customUrl'}).then(p => p.value);

  try {
    bootstrapApplication(AppComponent, {
      providers: [
        importProvidersFrom(BrowserModule, IonicModule.forRoot({
          // mode: isPlatform('android') ? 'md' : 'ios',
        }), GraphQLModule, TranslateModule.forRoot({
          defaultLanguage: 'pl',
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
          },
        })),
        provideRouter(routes, withPreloading(PreloadAllModules)),
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: ErrorHandler, useClass: GlobalErrorHandlerService},
        {provide: TitleStrategy, useClass: CustomTitleStrategy},
        {provide: APP_INITIALIZER, useFactory: initializeAppFactory, deps: [AuthService], multi: true},
        provideHttpClient(withInterceptorsFromDi()),
        {provide: CONFIGURED_ENVIRONMENT, useValue: configuredEnvironment},
        {provide: CONFIGURED_CUSTOM_URL, useValue: configuredCustomUrl},
        {
          provide: BASE_URL, useFactory: () => {
            if (!configuredEnvironment) {
              return URLs['production'];
            }
            if (isPredefinedEnvironment(configuredEnvironment)) {
              return URLs[configuredEnvironment];
            }
            return configuredCustomUrl || URLs['production'];
          }
        },
      ]
    })
  } catch (error) {
    console.error(error);
  }
}

main();


export type PredefinedEnvironment = 'production' | 'development';

const URLs: Record<PredefinedEnvironment, string> = {
  'production': 'https://api.doggo.podlodowski.it',
  'development': 'https://api.doggo-dev.podlodowski.it',
}

const isPredefinedEnvironment = (env: string): env is PredefinedEnvironment => {
  return Object.keys(URLs).includes(env);
}
