import {Routes} from "@angular/router";
import {introGuard} from "@app/guards/intro.guard";

export const routes: Routes = [
  {
    path: 'intro',
    loadComponent: () => import('./pages/intro/intro.page').then(m => m.IntroPage)
  },
  {
    path: '',
    loadChildren: () =>
      import('@app/tabs/tabs.routes').then((m) => m.routes),
    canActivate: [introGuard],
  },
];
