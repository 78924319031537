import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {NgModule} from '@angular/core';
import {ApolloClientOptions, ApolloLink, InMemoryCache} from '@apollo/client/core';
import {AppSettingsService, FirebaseAuthenticationService} from "@app/core";
import {setContext} from "@apollo/client/link/context";

export const apolloCache = new InMemoryCache();

export function createApollo(httpLink: HttpLink, firebaseAuthenticationService: FirebaseAuthenticationService, appSettingsService: AppSettingsService): ApolloClientOptions<any> {
    const baseUrl = appSettingsService.getBaseUrl();

    const http = httpLink.create({uri: `${baseUrl}/graphql`});

    const withToken = setContext(async () =>{
        const token = await firebaseAuthenticationService.getIdToken();
        return { token }
    })

    const auth = new ApolloLink((operation, forward) => {
        const { token } = operation.getContext();
        operation.setContext(() => ({
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            }
        }));
        return forward(operation);
    });
    return {
        link: ApolloLink.from([withToken, auth.concat(http)]),
        cache: apolloCache,
    };

}

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink, FirebaseAuthenticationService, AppSettingsService],
        },
    ],
})
export class GraphQLModule {
}
