import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class TranslatedTitleResolver implements Resolve<string> {
    constructor(
        private readonly translate: TranslateService,
    ) {}

    async resolve(route: ActivatedRouteSnapshot): Promise<string> {
        if (!route.data.titleKey) {
            return '';
        }

        return Promise.resolve(
            this.translate.instant(route.data.titleKey)
        );
    }
}
