import {Injectable} from "@angular/core";
import {Preferences} from "@capacitor/preferences";

@Injectable({
    providedIn: 'root',
})
export class AppSettingsService {
    private settings: AppSettings = {
        environment: null,
        customUrl: null
    };

    constructor() {
        const keys: (keyof AppSettings)[] = Object.keys(this.settings) as (keyof AppSettings)[];
        for (const key of keys) {
            this.load(key);
        }
    }

    public get(key: keyof AppSettings): string | null {
        return this.settings[key];
    }

    public getBaseUrl(): string {
        if (!this.settings.environment) {
            return URLs['production'];
        }
        if (isPredefinedEnvironment(this.settings.environment)) {
            return URLs[this.settings.environment];
        }
        return this.settings.customUrl || URLs['production'];
    }

    private load(key: keyof AppSettings) {
        // @ts-ignore
        Preferences.get({key}).then(pref => this.settings[key] = pref.value)
    }
}

export type PredefinedEnvironment = 'production' | 'development';
export interface AppSettings {
    environment: PredefinedEnvironment | 'custom' | null;
    customUrl: string | null;
}

const URLs: Record<PredefinedEnvironment, string> = {
    'production': 'https://api.doggo.podlodowski.it',
    'development': 'https://api.doggo-dev.podlodowski.it',
}

const isPredefinedEnvironment = (env: string): env is PredefinedEnvironment => {
    return Object.keys(URLs).includes(env);
}