import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {Injector, NgModule} from '@angular/core';
import {ApolloClientOptions, ApolloLink, InMemoryCache} from '@apollo/client/core';
import {AuthService} from "@app/services";
import {setContext} from "@apollo/client/link/context";
import {BASE_URL} from "@app/injectors";

export const apolloCache = new InMemoryCache();

export function createApollo(httpLink: HttpLink, auth: AuthService, injector: Injector): ApolloClientOptions<any> {
    const baseUrl = injector.get(BASE_URL);

    const http = httpLink.create({uri: `${baseUrl}/graphql`});

    const withToken = setContext(async () =>{
        const token = auth.token;
        return { token }
    })

    const authLink = new ApolloLink((operation, forward) => {
        const { token } = operation.getContext();
        operation.setContext(() => ({
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            }
        }));
        return forward(operation);
    });
    return {
        link: ApolloLink.from([withToken, authLink.concat(http)]),
        cache: apolloCache,
    };

}

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink, AuthService, Injector],
        },
    ],
})
export class GraphQLModule {
}
