import {Component} from '@angular/core';
import {register} from "swiper/element/bundle";
import {TranslateService} from "@ngx-translate/core";
import {Device} from "@capacitor/device";
import {ConsentStatus, ConsentType, FirebaseAnalytics} from "@capacitor-firebase/analytics";
import {SplashScreen} from "@capacitor/splash-screen";
import {Preferences} from "@capacitor/preferences";
import { IonicModule } from '@ionic/angular';
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {AuthService} from "@app/services";
import {firstValueFrom} from "rxjs";

register();

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    standalone: true,
    imports: [IonicModule],
})
export class AppComponent {
    constructor(
        private readonly translate: TranslateService,
    ) {
        this.initializeLanguage();
        this.initializeAnalytics();
        SplashScreen.hide();
    }

    private async initializeLanguage() {
        let lang = await Device.getLanguageCode().then(r => r.value);
        const prefLang = await Preferences.get({key: 'lang'}).then(p => p.value);
        if (prefLang) {
          lang = prefLang;
        }
        this.translate.use(lang);
        await FirebaseAnalytics.setUserProperty({
            key: 'language',
            value: lang
        });
    }

    private async initializeAnalytics() {
        await FirebaseAnalytics.setConsent({
            type: ConsentType.AnalyticsStorage, status: ConsentStatus.Granted
        })
    }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initializeAppFactory(auth: AuthService): () => Promise<void> {
  return async () => {
    await auth.initialize();
    await firstValueFrom(auth.currentUser$);
  }
}
