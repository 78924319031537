import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleStrategy, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CustomTitleStrategy extends TitleStrategy {
    private defaultPrefix = 'Doggo';

    constructor(private titleService: Title) {
        super();
    }

    override updateTitle(snapshot: RouterStateSnapshot): void {
        const title = this.buildTitle(snapshot);

        if (title) {
            // Set the title with a prefix
            this.titleService.setTitle(`${this.defaultPrefix} - ${title}`);
        } else {
            // Fallback to default prefix if no title is provided
            this.titleService.setTitle(this.defaultPrefix);
        }
    }
}